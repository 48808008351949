import React from "react";
import "./TermAndConditions.css";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
const TermAndConditions = () => {
  return (
    <div>
      <Navbar />
      <div className="terms-container">
        <h1>Terms and Conditions of Service</h1>
        <p>
          <strong>Last updated:</strong> 9 June 2022
        </p>

        <h2>Introduction</h2>
        <p>
          These Terms and Conditions ("Terms" or "Agreement") govern your access
          to and use of the services provided by <strong>Bullz.games</strong>{" "}
          (the "Website"). By using our Website, you agree to be bound by these
          Terms. If you do not agree, you must stop using the Website and
          Services immediately.
        </p>

        <h2>Company Information</h2>
        <p>
          Bullz.games is operated by <strong>Bullz Gaming N.V.</strong>, a
          company duly incorporated under the laws of Curaçao with company
          number 1456789, and registered office at Brionplein 4, Willemstad,
          Curaçao.
        </p>

        <h2>Acceptance</h2>
        <p>By registering an account, you agree to:</p>
        <ul>
          <li>These Terms & Conditions;</li>
          <li>Our Privacy Policy;</li>
          <li>Our Cookies Policy;</li>
          <li>
            Any specific game rules applicable to the Services, such as limits
            and maximum winnings;
          </li>
          <li>
            Any additional terms related to promotions, bonuses, or other
            features of the Services.
          </li>
        </ul>
        <p>
          You are responsible for regularly reviewing these Terms, as updates
          may be applied periodically. Continued use of the Website constitutes
          acceptance of any changes.
        </p>

        <h2>Accounts and Verification</h2>
        <p>To create an account on Bullz.games, you must:</p>
        <ul>
          <li>
            Be at least 18 years old or the legal gambling age in your
            jurisdiction.
          </li>
          <li>Provide accurate and up-to-date personal information.</li>
          <li>
            Use the account for personal purposes only and not on behalf of a
            third party.
          </li>
        </ul>
        <p>
          We reserve the right to limit accounts to one per individual,
          household, email address, IP address, or payment method. Violations
          may result in account suspension and withheld funds.
        </p>

        <h2>Suspension and Closure</h2>
        <p>
          Bullz.games reserves the right to suspend or close accounts in cases
          of:
        </p>
        <ul>
          <li>Suspected fraudulent or illegal activity.</li>
          <li>Non-compliance with these Terms or applicable laws.</li>
          <li>
            Incomplete verification of identity, location, or source of funds.
          </li>
        </ul>
        <p>
          If your account is suspended or closed, we may withhold any winnings
          or funds associated with your account.
        </p>

        <h2>Jurisdiction & Restricted Territories</h2>
        <p>
          Bullz.games services are not available in certain restricted
          territories. By using our Website, you confirm that you are not
          located in a restricted jurisdiction. Attempts to bypass
          geo-restrictions using VPNs or proxies are prohibited.
        </p>

        <h2>Deposits and Withdrawals</h2>
        <p>
          All deposits must come from authorized payment methods and be intended
          for use on the Services. Withdrawals are subject to verification
          checks and may be delayed or denied if irregularities are detected.
          Any funds erroneously credited to your account must be returned
          immediately upon request.
        </p>

        <h2>Prohibited Activities</h2>
        <p>You may not use the Website for:</p>
        <ul>
          <li>Illegal or fraudulent purposes.</li>
          <li>Bypassing geo-restrictions through VPNs or proxies.</li>
          <li>
            Engaging in unfair play, collusion, or using automated software to
            manipulate outcomes.
          </li>
        </ul>
        <p>Violations may result in account suspension and legal action.</p>

        <h2>Bonuses and Promotions</h2>
        <p>
          Bullz.games may offer bonuses or promotions from time to time. These
          are subject to specific terms and conditions. Abuse of bonuses or
          promotions may result in forfeiture and account suspension.
        </p>

        <h2>Intellectual Property</h2>
        <p>
          All content on Bullz.games, including trademarks, software, and design
          elements, is owned by Bullz Gaming N.V. You may not copy, distribute,
          or modify any materials without written permission.
        </p>

        <h2>Disputes</h2>
        <p>
          If you have a dispute, please contact our support team at{" "}
          <a href="mailto:complaints@bullz.games">complaints@bullz.games</a>.
          Any unresolved disputes will be governed by the laws of Curaçao.
        </p>

        <h2>Changes to Terms</h2>
        <p>
          We reserve the right to update these Terms at any time. Continued use
          of the Website after changes are posted constitutes acceptance of the
          revised Terms.
        </p>

        <h2>Contact Us</h2>
        <p>
          For any questions or concerns, please contact us at{" "}
          <a href="mailto:support@bullz.games">support@bullz.games</a>.
        </p>
      </div>
      <footer className="footer">
        <div className="footer-texts">
          <p>©2024 bullz.games ALL RIGHTS RESERVED.</p>
        </div>
        <div className="footer-nav">
          <Link to="/terms-conditions">
            <span>Terms and conditions</span>
          </Link>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
          <a
            href="https://bullz.games"
            target="_blank"
            rel="noopener noreferrer"
          >
            White Label Program
          </a>
        </div>
        <div className="footer-texts">
          <p>
            Bullz.games is operated by Bullz Gaming N.V., a company registered
            under the laws of Curaçao, with company registration number 1456789
            and a registered address at Brionplein 4, Willemstad, Curaçao. Bullz
            Gaming N.V. is authorized and regulated by the Curaçao Gaming
            Control Board under its valid Certificate of Operation (License No.
            2024-1520-GCB).
          </p>
          <p>
            For specific payment transactions, Bullz Payment Services Limited
            acts as the designated payment agent. This entity is registered
            under the laws of Cyprus, with a registered address at 20 Makariou
            III Avenue, MARINA TOWER, 1087, Nicosia, Cyprus, and company
            registration number HE507890. Payment processing and related
            services are conducted in accordance with applicable laws to ensure
            secure and compliant transactions
          </p>
          <p>
            Bullz.games is committed to upholding the highest standards of fair
            play and player protection. We operate under strict compliance with
            the gaming regulations of our licensed jurisdictions. Our priority
            is to provide a safe, transparent, and responsible gaming
            environment. Players are encouraged to gamble responsibly and to be
            aware of the risks associated with online gaming. <br />
            <br />
            Disclaimer: Crypto trading on Bullz.games is not considered gambling
            and therefore does not fall under the scope of our gaming license.
            Unauthorized use of the website or its content is strictly
            prohibited.
          </p>
        </div>
        <hr className="footer-separator" />
        <div className="payment-icons">
          <img src="images/bullzimage/gcb.svg" alt="gcb" />
          <img src="images/bullzimage/18.png" alt="18" />
          <img src="images/bullzimage/visa.png" alt="visa" />
          <img src="images/bullzimage/mastercard.png" alt="mastercard" />
          <img src="images/bullzimage/amex.png" alt="amex" />
          <img src="images/bullzimage/paypal.png" alt="PayPal" />
        </div>
      </footer>
    </div>
  );
};

export default TermAndConditions;
