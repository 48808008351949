import React from "react";
import "./PrivacyPolicy.css";
import Navbar from "../../components/Navbar/Navbar";
import { Link } from "react-router-dom";
const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="privacy-policy-container">
        <h1>Privacy Policy</h1>
        <p>
          <strong>Last updated:</strong> 14 June 2022
        </p>
        <p>
          This Privacy Policy explains the policies and procedures of
          <strong> Bullz.games</strong> regarding the collection, use, and
          disclosure of your information when you use our Service. It also
          explains your privacy rights and how the law protects you.
        </p>
        <p>
          We use your personal data to provide and improve the Service. By using
          the Service, you agree to the collection and use of information in
          accordance with this Privacy Policy. <strong>Bullz.games</strong> is
          operated by <strong>Bullz Gaming N.V.</strong>, a company registered
          under the laws of Curaçao with its registered office at Brionplein 4,
          Willemstad, Curaçao.
        </p>

        <h2>Interpretation and Definitions</h2>
        <h3>Interpretation</h3>
        <p>
          Words with initial capital letters have meanings defined under the
          following conditions. These definitions apply regardless of whether
          the terms appear in singular or plural form.
        </p>
        <h3>Definitions</h3>
        <ul>
          <li>
            <strong>You:</strong> Refers to the individual accessing or using
            the Service.
          </li>
          <li>
            <strong>Company:</strong> Refers to Bullz Gaming N.V.
          </li>
          <li>
            <strong>Website:</strong> Refers to bullz.games, accessible at{" "}
            <a href="https://www.bullz.games">www.bullz.games</a>.
          </li>
          <li>
            <strong>Service:</strong> Refers to the Website.
          </li>
          <li>
            <strong>Country:</strong> Refers to Curaçao.
          </li>
          <li>
            <strong>Personal Data:</strong> Refers to information relating to an
            identified or identifiable individual, such as names, identification
            numbers, or online identifiers.
          </li>
          <li>
            <strong>Cookies:</strong> Small files stored on your device to
            enhance your experience.
          </li>
          <li>
            <strong>Data Controller:</strong> The entity that determines the
            purposes of processing personal data.
          </li>
        </ul>

        <h2>Collecting and Using Your Personal Data</h2>
        <h3>Types of Data Collected</h3>
        <h4>Personal Data</h4>
        <p>We may collect the following personally identifiable information:</p>
        <ul>
          <li>Email address</li>
          <li>First and last name</li>
          <li>Phone number</li>
          <li>Address, State, ZIP/Postal code, City</li>
          <li>Bank account information for transactions</li>
          <li>Date of birth or identification documents for verification</li>
        </ul>

        <h4>Usage Data</h4>
        <p>
          Usage data includes information such as your device's IP address,
          browser type, and pages visited.
        </p>

        <h2>Tracking Technologies and Cookies</h2>
        <p>
          We use cookies and similar technologies to track user activity. You
          can manage your cookie preferences in your browser settings.
        </p>

        <h2>Your Rights Under GDPR</h2>
        <p>
          If you are located in the EU, you have rights to access, update, or
          delete your data, object to processing, and withdraw consent. Contact
          us at <a href="mailto:privacy@bullz.games">privacy@bullz.games</a> to
          exercise these rights.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have questions, contact us via email at
          <a href="mailto:support@bullz.games">support@bullz.games</a> or by
          phone at +599 9 1234567 (09:00 - 16:00 UTC).
        </p>
      </div>
      <footer className="footer">
        <div className="footer-texts">
          <p>©2024 bullz.games ALL RIGHTS RESERVED.</p>
        </div>
        <div className="footer-nav">
          <Link to="/terms-conditions">
            <span>Terms and conditions</span>
          </Link>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
          <a
            href="https://bullz.games"
            target="_blank"
            rel="noopener noreferrer"
          >
            White Label Program
          </a>
        </div>
        <div className="footer-texts">
          <p>
            Bullz.games is operated by Bullz Gaming N.V., a company registered
            under the laws of Curaçao, with company registration number 1456789
            and a registered address at Brionplein 4, Willemstad, Curaçao. Bullz
            Gaming N.V. is authorized and regulated by the Curaçao Gaming
            Control Board under its valid Certificate of Operation (License No.
            2024-1520-GCB).
          </p>
          <p>
            For specific payment transactions, Bullz Payment Services Limited
            acts as the designated payment agent. This entity is registered
            under the laws of Cyprus, with a registered address at 20 Makariou
            III Avenue, MARINA TOWER, 1087, Nicosia, Cyprus, and company
            registration number HE507890. Payment processing and related
            services are conducted in accordance with applicable laws to ensure
            secure and compliant transactions
          </p>
          <p>
            Bullz.games is committed to upholding the highest standards of fair
            play and player protection. We operate under strict compliance with
            the gaming regulations of our licensed jurisdictions. Our priority
            is to provide a safe, transparent, and responsible gaming
            environment. Players are encouraged to gamble responsibly and to be
            aware of the risks associated with online gaming. <br />
            <br />
            Disclaimer: Crypto trading on Bullz.games is not considered gambling
            and therefore does not fall under the scope of our gaming license.
            Unauthorized use of the website or its content is strictly
            prohibited.
          </p>
        </div>
        <hr className="footer-separator" />
        <div className="payment-icons">
          <img src="images/bullzimage/gcb.svg" alt="gcb" />
          <img src="images/bullzimage/18.png" alt="18" />
          <img src="images/bullzimage/visa.png" alt="visa" />
          <img src="images/bullzimage/mastercard.png" alt="mastercard" />
          <img src="images/bullzimage/amex.png" alt="amex" />
          <img src="images/bullzimage/paypal.png" alt="PayPal" />
        </div>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
