import CryptoJS from 'crypto-js';

const keyHex = process.env.REACT_APP_ENCRYPTION_KEY;

if (!keyHex) {
  throw new Error('REACT_APP_ENCRYPTION_KEY is not set in environment variables');
}

// Convertit la clé hexadécimale en format utilisable par CryptoJS
const key = CryptoJS.enc.Hex.parse(keyHex);

// Fonction de déchiffrement
export const decrypt = (encrypted) => {
  // Convertit l'IV de chaîne hexadécimale en format CryptoJS
  const iv = CryptoJS.enc.Hex.parse(encrypted.iv);

  // Déchiffre les données encryptées
  const decrypted = CryptoJS.AES.decrypt(encrypted.encryptedData, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  // Convertit le résultat en texte UTF-8 lisible
  return decrypted.toString(CryptoJS.enc.Utf8);
};
