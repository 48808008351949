import React, { useEffect, useState } from "react";
import connectToWebSocket from "../components/connectToWebSocket";
import cryptoConfig from "../cryptoConfig";
import "../index.css";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion"; // Import Framer Motion
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import Navbar from "../components/Navbar/Navbar";

const Homepage = () => {
  const [cryptoData, setCryptoData] = useState([]); // État pour stocker les coins reçus
  const [latestBets, setLatestBets] = useState([]);
  const [cryptoInfo, setCryptoInfo] = useState([]);
  const [cryptoList, setCryptoList] = useState([]);
  const [valueDirections, setValueDirections] = useState({});
  const [volatilities, setVolatilities] = useState({});
  const [historicalData, setHistoricalData] = useState({});

  const sortBets = (bets) => {
    return bets.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
  };

  useEffect(() => {
    if (cryptoData.data) {
      setHistoricalData((prev) => {
        const updated = { ...prev };
        for (const coinSymbol in cryptoData.data) {
          if (!updated[coinSymbol]) {
            updated[coinSymbol] = [];
          }
          updated[coinSymbol] = [
            ...updated[coinSymbol],
            cryptoData.data[coinSymbol],
          ].slice(-500);
        }
        return updated;
      });
    }
  }, [cryptoData]);

  useEffect(() => {
    const wss = connectToWebSocket((data) => {
      if (data.type === "cryptoData") {
        setCryptoData(data.data);
      } else if (data.type === "initial") {
        setLatestBets(data.data);
        if (data.info) {
          setCryptoInfo(data.info); // Ajout des informations supplémentaires
        }
      } else if (data.type === "ActiveCoins") {
        setCryptoInfo(data.data);
      } else if (data.type === "cashedOutBet") {
        setLatestBets((prevBets) => {
          const updatedBets = sortBets([
            data.data,
            ...prevBets.filter((bet) => bet._id !== data.data._id),
          ]).slice(0, 15);
          return updatedBets;
        });
      }
    });

    return () => {
      wss.close();
    };
  }, []);

  // Fetch initial crypto info
  useEffect(() => {
    const getCryptoInfo = async () => {
      if (Array.isArray(cryptoInfo)) {
        setCryptoList(
          cryptoInfo.map((crypto) => ({
            ...crypto,
            price: null,
            previousPrice: null,
          }))
        );
      } else if (typeof cryptoInfo === "object") {
        setCryptoList(
          Object.values(cryptoInfo).map((crypto) => ({
            ...crypto,
            price: null,
            previousPrice: null,
          }))
        );
      } else {
        console.error("Unexpected data format:", cryptoInfo);
      }
    };

    getCryptoInfo();
  }, [cryptoInfo]);

  // Update cryptoList with prices from cryptoData when cryptoData changes
  useEffect(() => {
    if (cryptoData.data) {
      setCryptoList((prevCryptoList) =>
        prevCryptoList.map((crypto) => {
          const cryptoName = crypto.symbol + "USDT";
          const newPrice = cryptoData.data[cryptoName] || null;

          return {
            ...crypto,
            price: newPrice,
            previousPrice: crypto.price,
            cryptoName: cryptoName,
          };
        })
      );
    }
  }, [cryptoData]);

  const getAnimationProps = () => {
    return {
      initial: { opacity: 0, y: -70 },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: 70 },
      transition: { duration: 0.5 },
    };
  };

  useEffect(() => {
    if (cryptoList.length > 0) {
      const updatedDirections = {};
      cryptoList.forEach((crypto) => {
        if (crypto.price !== null && crypto.previousPrice !== null) {
          if (crypto.price > crypto.previousPrice) {
            updatedDirections[crypto.cryptoName] = "profit";
          } else if (crypto.price < crypto.previousPrice) {
            updatedDirections[crypto.cryptoName] = "loss";
          }
        }
      });
      setValueDirections((prevDirections) => ({
        ...prevDirections,
        ...updatedDirections,
      }));
    }
  }, [cryptoList]);

  const getFormattedValue = (value, symbol) => {
    if (value !== null) {
      const precision = cryptoConfig[symbol]?.precision || 2;

      const colorClass = valueDirections[symbol];

      const formattedValue = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision,
      }).format(value);

      return (
        <span className={`asset-value ${colorClass}`}>{formattedValue}</span>
      );
    }

    return <span className="asset-value">-</span>;
  };

  const calculateVolatility = (prices) => {
    if (prices.length < 2)
      return {
        text: "Very Low Volatility",
        width: "0%",
        background: "#1a1d29",
      };

    const returns = prices.slice(1).map((p, i) => (p - prices[i]) / prices[i]);
    const mean = returns.reduce((a, b) => a + b, 0) / returns.length;
    const squareDiffs = returns.map((val) => Math.pow(val - mean, 2));
    const avgSquareDiff =
      squareDiffs.reduce((a, b) => a + b, 0) / squareDiffs.length;
    const volatility = Math.sqrt(avgSquareDiff) * Math.sqrt(60);

    const maxVolatilityForBar = 0.02;
    const volatilityPercentage = Math.min(
      (volatility / maxVolatilityForBar) * 1000,
      100
    );

    let background, text;
    if (volatilityPercentage < 10) {
      background = "linear-gradient(to right, #29f4a3, #3bb2e7)";
      text = "Very Low Volatility";
    } else if (volatilityPercentage < 40) {
      background = "linear-gradient(to right, #29f4a3, #3bb2e7)";
      text = "Low Volatility";
    } else if (volatilityPercentage < 60) {
      background = "linear-gradient(to right, #29f4a3, #3bb2e7, #a233e7)";
      text = "Medium Volatility";
    } else if (volatilityPercentage < 90) {
      background =
        "linear-gradient(to right, #29f4a3, #3bb2e7, #a233e7, #ff4191)";
      text = "High Volatility";
    } else {
      background =
        "linear-gradient(to right, #29f4a3, #3bb2e7, #a233e7, #ff4191)";
      text = "Very High Volatility";
    }

    return { text, width: `${volatilityPercentage}%`, background };
  };

  useEffect(() => {
    const newVolatilities = {};
    for (const coinSymbol in historicalData) {
      const volatilityResult = calculateVolatility(historicalData[coinSymbol]);
      newVolatilities[coinSymbol] = volatilityResult;
    }
    setVolatilities(newVolatilities);
  }, [historicalData]);

  return (
    <div className="App">
      {/* Navbar */}
      <Navbar />

      {/* Section 1: Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>
            SIGN UP & RECEIVE <br />
            <span>100% WELCOME BONUS!</span>
          </h1>
          <p>
            Bet up, bet down, and stack <br />
            crypto gains <span> up to x 5,000 !</span>
          </p>
          <button className="cta-btn">
            <a href="https://app.bullz.games">START PLAYING !</a>
          </button>
        </div>
        <img
          src="/images/bullzimage/newHomeBull.png"
          alt="bullz"
          className="hero-absolute-image"
        />
        <img
          src="/images/bullzimage/bullzmobile.png"
          alt="bullz"
          className="bullzmobile"
        />
      </section>

      {/* Section 2: How it works */}
      <section className="how-it-works">
        <h2 className="section-title">
          How it works - Quick, Easy, Cashable :
        </h2>
        <div className="boxes">
          {[
            {
              title: "1. \u00A0Pick your asset",
              description: (
                <>
                  Choose a hot <br /> crypto to bet on
                </>
              ),
              image: "/images/bullzimage/coins1.png",
            },
            {
              title: "2. \u00A0Place your bet",
              description: (
                <>
                  Set the amount <br /> you want to bet
                </>
              ),
              image: "/images/bullzimage/setbet.png",
            },
            {
              title: "3. \u00A0Set the multiplier",
              description: (
                <>
                  Win more <br /> up to x5000!
                </>
              ),
              image: "/images/bullzimage/multiplier.png",
            },
            {
              title: "4. \u00A0Bust and Target Price",
              description: (
                <>
                  Hit the target <br /> price to double.
                </>
              ),
              image: "/images/bullzimage/target.png",
            },
            {
              title: "5. \u00A0Cash-out Anytime",
              description: (
                <>
                  Cash-out in <br /> one click
                </>
              ),
              image: "/images/bullzimage/cashout.png",
            },
          ].map((item, index) => (
            <div className="box" key={index}>
              <h3>{item.title}</h3>
              <div className="box-content">
                <p className="description">{item.description}</p>
                <img src={item.image} alt="Icon" className="mini-image" />
              </div>
            </div>
          ))}
        </div>

        <div className="video-placeholder">
          <div className="device-image">
            <div className="image-overlay"></div>
            <img
              src="/images/bullzimage/gameplay.png"
              alt="Gameplay"
              className="gameplay-image"
            />
            <button className="cta-button-demo">PLAY DEMO !</button>
          </div>
        </div>
      </section>

      {/* Section 3: Description et boxes */}
      <section className="description-boxes">
        <div className="section-content">
          <h2>Players Levels - Power Up Your Profile :</h2>
          <p>
            Each level unlocks exclusive perks and bigger rewards, giving you
            advanced tools to refine your skills and maximize gains. Progress
            brings new opportunities, from small wins to massive payouts,
            marking your journey to becoming a trading legend.
            <br /> <br /> Take the challenge, level up, and discover what
            greatness lies ahead.
            {/* <span>More Details</span> */}
          </p>
          <div className="boxes-grid">
            {[
              {
                title: "Mouse (Explorer) :",
                description:
                  "Tiny but nimble. Take your first steps into the game",
                image: "/images/bullzimage/newmouse.png",
                mini_image: "/images/bullzimage/mouseprogress.png",
              },
              {
                title: "Bunny (Newbie) :",
                description:
                  "Cautious but quick. Dip your toes before you go big",
                image: "/images/bullzimage/newbunny.png",
                mini_image: "/images/bullzimage/bunnyprogress.png",
              },
              {
                title: "Fox (Intermediate) :",
                description:
                  "Smart moves only - start playing the game like a pro",
                image: "/images/bullzimage/newfox.png",
                mini_image: "/images/bullzimage/foxprogress.png",
              },
              {
                title: "Wolf (Advanced) :",
                description: "Time to go for it! Big bets, calculated risks",
                image: "/images/bullzimage/newwolf.png",
                mini_image: "/images/bullzimage/wolfprogress.png",
              },
              {
                title: "Eagle (Expert) :",
                description:
                  "Bird's eye view of the market. You've got the moves",
                image: "/images/bullzimage/neweagle.png",
                mini_image: "/images/bullzimage/eagleprogress.png",
              },
              {
                title: "Bull (Master) :",
                description:
                  "Strength and swagger. For the pros who own the game.",
                image: "/images/bullzimage/newbull.png",
                mini_image: "/images/bullzimage/bullprogress.png",
              },
            ].map((box, index) => (
              <div className="box-sec3" key={index}>
                {/* Côté gauche : Image */}
                <div className="box-image">
                  <img src={box.image} alt={`Image ${index + 1}`} />
                </div>
                {/* Côté droit : Contenu */}
                <div className="box-content-sec3">
                  <h3>{box.title}</h3>
                  <p>{box.description}</p>
                  <img
                    src={box.mini_image}
                    alt={box.mini_image}
                    className="mini-image"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="assets-rewards">
        <div className="assets-rewards-left">
          <h1>
            Electrifying <span>Rewards</span>,
            <br className="responsive-break" /> <span>Bonuses</span> and{" "}
            <span>Surprises</span> awaits
          </h1>
          <ul className="rewards-list">
            <li>New player Bonuses</li>
            <li>Double your deposit</li>
            <li>Daily rewards</li>
            <li>VIP Club</li>
            <li>And many more</li>
          </ul>
          <img
            src="/images/bullzimage/eagleReward.png"
            className="eagle-mobile"
            alt="Eagle Rewards"
          />
          <button className="cta-button">Start Playing!</button>
        </div>
        <div className="assets-rewards-right">
          <img src="/images/bullzimage/wolfeagle.png" alt="Eagle Rewards" />
        </div>
      </section>

      <section className="description-boxes">
        <div className="section-content"></div>
      </section>

      {/* Section 4: Assets et Latest Bets */}
      <section className="assets-bets">
        <div className="assets">
          <h2>Assets Available</h2>
          <div className="assets-grid">
            {cryptoList
              .filter((crypto) => crypto.price !== null)
              .map((crypto, index) => {
                const coinSymbol = crypto.cryptoName;
                const vol = volatilities[coinSymbol] || {
                  text: "Very Low Volatility",
                  width: "0%",
                  background: "#1a1d29",
                };

                return crypto.active ? (
                  <div className="asset-box" key={index}>
                    <div className="asset-header">
                      <img
                        src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${crypto.id}.png`}
                        alt={crypto.id}
                        className="asset-logo"
                      />
                      <div className="asset-info">
                        <span className="asset-name">{crypto.cryptoName}</span>
                        <div className="asset-value-row">
                          {getFormattedValue(crypto.price, crypto.cryptoName)}
                          {/* <span
                        className={`asset-change ${
                          key.change.startsWith("-") ? "negative" : ""
                        }`}
                      >
                        +1.2%
                      </span> */}
                        </div>
                      </div>
                    </div>
                    <span className="asset-volatility">{vol.text}</span>
                    <div className="progress-bar-container">
                      <div
                        className={`progress-bar ${
                          vol.width < 100 ? "progress-incomplete" : ""
                        }`}
                        style={{
                          width: vol.width,
                          background: vol.background,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : null;
              })}
          </div>
        </div>
        <div className="latest-bets">
          <h2>Latest Bets</h2>
          <div className="latest-bets-scrollable">
            <AnimatePresence>
              {" "}
              {/* Gère les animations d'entrée/sortie */}
              {latestBets.map((bet, index) => (
                <motion.div
                  key={bet._id}
                  {...getAnimationProps()} // Ajoute les animations
                  className={`bet-row ${
                    index % 2 === 0 ? "bg-dark" : "bg-darker"
                  }`}
                >
                  <div className="bet-player">
                    <img
                      src={`https://iframe.bullz.games/images/avatar/${bet.avatar}`}
                      alt={bet.avatar}
                      className="user-image"
                    />
                    <div className="bet-player-info">
                      <span className="bet-user-name">{bet.username}</span>
                      <span className="bet-amount">$ {bet.amount}</span>
                    </div>
                  </div>

                  <div className="bet-pnl">
                    <span className="bet-pnl-label">P&L :</span>
                    <span
                      className={`bet-pnl-value ${
                        bet.profitOrLoss < 0 ? "negative" : ""
                      }`}
                    >
                      $ {bet.profitOrLoss}
                    </span>
                  </div>

                  <div className="bet-asset">
                    <div className="asset-icon">
                      <img
                        src={`https://s2.coinmarketcap.com/static/img/coins/64x64/${bet.cryptoId}.png`}
                        alt={bet.cryptoId}
                      />
                      <span
                        className={`trend-arrow ${
                          bet.direction === "up" ? "up" : "down"
                        }`}
                      >
                        {bet.direction === "up" ? (
                          <FaAngleDoubleUp />
                        ) : (
                          <FaAngleDoubleDown />
                        )}
                      </span>
                    </div>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </section>

      {/* Section 5: CTA Section */}
      <section className="cta-section">
        <div className="hero-content-end">
          <h1>
            Crank Up Your Wins <br />
            Rule The Game !
          </h1>
          <p>
            Bet up, bet down, and stack <br />
            crypto gains <span> up to x 1,000 !</span>
          </p>
          <button className="cta-btn">
            {" "}
            <a href="https://app.bullz.games">START PLAYING !</a>
          </button>
        </div>
        <img
          src="/images/bullzimage/familypicture.png"
          alt="bullz"
          className="cta-absolute-image"
        />
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-texts">
          <p>©2024 bullz.games ALL RIGHTS RESERVED.</p>
        </div>
        <div className="footer-nav">
          <Link to="/terms-conditions">
            <span>Terms and conditions</span>
          </Link>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
          <a
            href="https://bullz.games"
            target="_blank"
            rel="noopener noreferrer"
          >
            White Label Program
          </a>
        </div>
        <div className="footer-texts">
          <p>
            Bullz.games is operated by Bullz Gaming N.V., a company registered
            under the laws of Curaçao, with company registration number 1456789
            and a registered address at Brionplein 4, Willemstad, Curaçao. Bullz
            Gaming N.V. is authorized and regulated by the Curaçao Gaming
            Control Board under its valid Certificate of Operation (License No.
            2024-1520-GCB).
          </p>
          <p>
            For specific payment transactions, Bullz Payment Services Limited
            acts as the designated payment agent. This entity is registered
            under the laws of Cyprus, with a registered address at 20 Makariou
            III Avenue, MARINA TOWER, 1087, Nicosia, Cyprus, and company
            registration number HE507890. Payment processing and related
            services are conducted in accordance with applicable laws to ensure
            secure and compliant transactions
          </p>
          <p>
            Bullz.games is committed to upholding the highest standards of fair
            play and player protection. We operate under strict compliance with
            the gaming regulations of our licensed jurisdictions. Our priority
            is to provide a safe, transparent, and responsible gaming
            environment. Players are encouraged to gamble responsibly and to be
            aware of the risks associated with online gaming. <br />
            <br />
            Disclaimer: Crypto trading on Bullz.games is not considered gambling
            and therefore does not fall under the scope of our gaming license.
            Unauthorized use of the website or its content is strictly
            prohibited.
          </p>
        </div>
        <hr className="footer-separator" />
        <div className="payment-icons">
          <img src="images/bullzimage/gcb.svg" alt="gcb" />
          <img src="images/bullzimage/18.png" alt="18" />
          <img src="images/bullzimage/visa.png" alt="visa" />
          <img src="images/bullzimage/mastercard.png" alt="mastercard" />
          <img src="images/bullzimage/amex.png" alt="amex" />
          <img src="images/bullzimage/paypal.png" alt="PayPal" />
        </div>
      </footer>
    </div>
  );
};

export default Homepage;
